/* styles.css */
.custom-chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.minimize-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.minimize-button:hover {
  background-color: #c82333;
}
